<script setup lang="ts">
defineProps<{ expanded: boolean }>()
const client = useClient()
const supabase = useSupabaseClient()
const auth = useAuth()

const links = [
  [
    {
      icon: resolveComponent('IconForgd'),
      to: '/',
    },
  ],
  [
    {
      icon: 'i-heroicons-home',
      to: '/dashboard/',
      label: 'Home',
    },
    {
      icon: 'i-heroicons-squares-plus',
      to: '/token-designer/',
      label: 'Token Designer',
    },
    {
      icon: resolveComponent('IconToolsAndServices'),
      to: '/tools-and-services/',
      label: 'Tools & Services',
      children: [
        { label: 'Tokenomics', to: '/tokenomics/' },
        { label: 'Growth Capital', to: '/growth-capital/' },
        { label: 'AutoDistribution', to: '/auto-distribution/' },
        { label: 'Listing & Liquidity', to: '/listing-and-liquidity/' },
        { label: 'Business Intelligence', to: '/business-intelligence/' },
        { label: 'AMM²', to: '/automated-market-making/' },
        {
          label: 'Engage a Market Maker',
          to: '/engage-a-market-maker/',
        },
        {
          label: 'Market Maker Monitoring',
          to: '/market-maker-monitoring/start',
        },
      ],
    },
    {
      icon: resolveComponent('IconPathFinder'),
      to: '/pathfinder/',
      label: 'Pathfinder',
    },
    {
      icon: 'i-heroicons-document-text',
      to: '/about/',
      label: 'About',
      children: [
        { label: 'What is Forgd?', to: '/what-is-forgd/' },
        { label: 'Fees and pricing', to: '/fees-and-pricing/' },
        { label: 'Coming Soon', to: '/coming-soon/' },
      ],
    },
  ],
  [
    {
      icon: 'i-heroicons-question-mark-circle',
      // TODO fix link
      to: '/support/',
      label: 'FAQs',
    },
    {
      icon: 'i-heroicons-chat-bubble-oval-left-ellipsis',
      to: '/support/',
      label: 'Support',
    },
    {
      icon: 'i-heroicons-cog-8-tooth',
      to: '/settings/',
      label: 'Settings',
    },
    {
      icon: 'i-heroicons-arrow-right-start-on-rectangle',
      label: 'Logout',
      on: {
        click: logout,
      },
    },
  ],
]

async function logout() {
  await supabase.auth.signOut()
  auth.logout()
}
</script>

<template>
  <div
    class="px-2 flex-shrink-0 border-r bg-forgd-primary-900 font-display max-h-screen z-10 pb-2 sticky top-0"
  >
    <nav
      class="flex flex-col justify-between h-full pt-8 pb-2"
      :class="expanded ? 'w-[260px]' : ''"
      aria-label="Site Navigation"
    >
      <div>
        <AppMenuExpandableNavigationList
          v-for="(link, key) in links.slice(0, 2)"
          :key="key"
          :items="link"
          :expanded="expanded"
          :class="key === 0 ? 'mb-8' : ''"
        />
      </div>
      <AppMenuExpandableNavigationList
        :items="links[links.length - 1]"
        :expanded="expanded"
      />
    </nav>
  </div>
</template>
