<script setup lang="ts">
import { withTrailingSlash } from 'ufo'
import type { VerticalNavigationLink } from '#ui/types'

const props = defineProps<{
  expanded: boolean
  items: (VerticalNavigationLink & { children: VerticalNavigationLink[], icon: any })[]
}>()

const route = useRoute()
function isCurrent(path: string) {
  return withTrailingSlash(route.path) === path || route.path.startsWith(path)
}

const buttonUi = {
  padding: { '2xs': 'p-2' },
  gap: { '2xs': 'gap-x-2' },
  variant: {
    solid: 'hover:bg-forgd-primary-700 bg-forgd-primary-900',
  },
}

const popoverUi = {
  ring: 'ring-forgd-primary-800',
  arrow: {
    base: 'invisible before:visible before:block before:rotate-45 before:z-[-1] before:w-2 before:h-2',
    ring: 'before:ring-1 before:ring-forgd-primary-900 dark:before:ring-gray-900',
    rounded: 'before:rounded-sm',
    background: 'before:bg-forgd-primary-900',
    shadow: 'before:shadow',
    placement: 'before:translate-x-[15px]',
  },
}
</script>

<template>
  <ul class="space-y-4">
    <li v-for="(item, key) in items" :key="key">
      <template v-if="!expanded">
        <UPopover v-if="item.label || item.children" mode="hover" :popper="{ placement: 'right', offsetDistance: 15, arrow: true }" :ui="popoverUi">
          <UButton size="2xs" color="forgd-primary" :to="item.children ? undefined : item.to" active-class="bg-forgd-primary-500" :active="isCurrent(item.to)" :ui="buttonUi" v-on="item.on ?? {}">
            <UIcon v-if="typeof item.icon === 'string'" :name="item.icon" class="w-5 h-5 text--bgd-200" />
            <component :is="item.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
          </UButton>
          <template #panel>
            <div class="text-left px-2 py-5 bg-forgd-primary-900 text-white text-xs">
              <ULink :to="item.to" class="px-3 whitespace-nowrap font-bold" v-on="item.on ?? {}">
                {{ item.label }}
              </ULink>
              <template v-if="item.children">
                <UDivider class="opacity-20 my-2" />
                <UVerticalNavigation :links="item.children" :ui="{ size: 'text-xs', inactive: 'text-forged-bgd-200 hover:before:bg-forgd-primary-500 hover:text-white' }" />
              </template>
            </div>
          </template>
        </UPopover>
        <UButton v-else size="2xs" color="forgd-primary" :to="item.children ? undefined : item.to" active-class="bg-forgd-primary-500" :active="isCurrent(item.to)" :ui="buttonUi">
          <UIcon v-if="typeof item.icon === 'string'" :name="item.icon" class="w-5 h-5 text--bgd-200" />
          <component :is="item.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
        </UButton>
      </template>
      <template v-else>
        <UAccordion v-if="item.children" :items="[item]">
          <template #default="{ item, open }">
            <UButton size="2xs" color="forgd-primary" :to="item.children ? undefined : item.to" active-class="bg-forgd-primary-500" :active="isCurrent(item.to)" :ui="buttonUi">
              <UIcon v-if="typeof item.icon === 'string'" :name="item.icon" class="w-5 h-5 text--bgd-200" />
              <component :is="item.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
              <span v-if="item.label" class="text-xs text-white font-normal">
                {{ item.label }}
              </span>
              <template #trailing>
                <UIcon
                  name="i-heroicons-chevron-down-20-solid"
                  class="w-5 h-5 ms-auto transform transition-transform duration-200 text-white"
                  :class="[open && 'rotate-180']"
                />
              </template>
            </UButton>
          </template>
          <template #item="{ item }">
            <ul class="space-y-1">
              <li v-for="(child, key) in item.children" :key="key">
                <UButton class="w-full" size="2xs" color="forgd-primary" :to="child.children ? undefined : child.to" active-class="bg-forgd-primary-500" :active="isCurrent(child.to)" :ui="buttonUi" v-on="child.on ?? {}">
                  <UIcon v-if="typeof child.icon === 'string'" :name="child.icon" class="w-5 h-5 text--bgd-200" />
                  <component :is="child.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
                  <div class="font-normal">
                    {{ child.label }}
                  </div>
                </UButton>
              </li>
            </ul>
          </template>
        </UAccordion>
        <UButton v-else size="2xs" class="w-full" color="forgd-primary" :to="item.children ? undefined : item.to" active-class="bg-forgd-primary-500" :active="isCurrent(item.to)" :ui="buttonUi" v-on="item.on ?? {}">
          <div class="flex items-center gap-2 w-full">
            <UIcon v-if="typeof item.icon === 'string'" :name="item.icon" class="w-5 h-5 text--bgd-200" />
            <component :is="item.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
            <div v-if="item.label" class="font-normal">
              {{ item.label }}
            </div>
          </div>
        </UButton>
      </template>
    </li>
  </ul>
</template>
